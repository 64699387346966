import { ColorValueHex, shadeColor } from './shadeColor'

export const customColorButtonProps = (
    buttonColor?: ColorValueHex | null | undefined,
    buttonTextColor?: ColorValueHex | null | undefined
) => {
    return buttonColor && buttonTextColor
        ? {
              backgroundColor: buttonColor,
              color: buttonTextColor,
              '&:not(:disabled):hover, &:not(:disabled):focus': {
                  borderColor: buttonColor,
                  backgroundColor: shadeColor(buttonColor, 60),
                  color: buttonColor,
              },
          }
        : {}
}
