import React from 'react'
import type { GetStaticPaths, GetStaticPropsContext } from 'next'
import { ParsedUrlQuery } from 'querystring'
import { defaultPrivateLabelPartnerPageProps } from '@/src/common/components/privateLabelPartnerSite/defaultPageProps'
import prisma from '../../../lib/prisma'
import { PRIVATE_LABEL_PAGE_TYPES } from '@/prisma/schemaConstants'
import { styled } from '@/src/stitches.config'
import Button from '../../..//src/common/components/marketing/buttons/Button'
import { useRouter } from 'next/router'
import { customColorButtonProps } from '@/src/common/utilities/customColorButtonProps'
import PrivateLabelPageProps from '../../..//src/common/components/privateLabelPartnerSite/PrivateLabelPageProps'
import {
    getStaticPathsForPrivateLabelMarketingPage,
} from '@/src/modules/private-label-partner-site/getStaticPathsForPrivateLabelMarketingPage'

const ContentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem',
})
const LeftColumn = styled('div', {
    display: 'block',
    width: '100%',
    '@md': {
        width: '48%',
    },
})
const ContentHeader = styled('p', {
    variants: {
        size: {
            small: {
                fontSize: '$6',
            },
            medium: {
                fontSize: '$7',
            },
            large: {
                fontSize: '$8',
            },
            extralarge: {
                fontSize: '$9',
            },
        },
    },
})
const ContentBody = styled('p', {
    width: '85%',
})

export const getStaticPaths: GetStaticPaths<PathProps> = async () => {
    return await getStaticPathsForPrivateLabelMarketingPage()
}

interface PathProps extends ParsedUrlQuery {
    site: string
}

export async function getStaticProps({
                                         params,
                                     }: GetStaticPropsContext<PathProps>) {
    if (!params?.site) {
        return { notFound: true }
    }

    let privateLabelPartnerPage
    try {
        privateLabelPartnerPage =
            await prisma.privateLabelPartnerPage.findFirstOrThrow({
                where: {
                    private_label_partner: {
                        subdomain: { equals: params.site },
                    },
                    page_type: PRIVATE_LABEL_PAGE_TYPES.LANDING_PAGE,
                },
                include: {
                    private_label_partner: true,
                },
            })
    } catch (e: any) {
        return {
            notFound: true,
        }
    }

    const capitalizedSubdomain: string =
        privateLabelPartnerPage.private_label_partner.subdomain
            .charAt(0)
            .toUpperCase() +
        privateLabelPartnerPage.private_label_partner.subdomain.slice(1)

    return {
        props: {
            ...defaultPrivateLabelPartnerPageProps,
            content: {
                data: {
                    title: `${capitalizedSubdomain} Main Page`,
                    description: `${capitalizedSubdomain} Main Page`,
                },
            },
            layout:
                Number(privateLabelPartnerPage.template_id) === 1
                    ? 'main'
                    : 'secondary',
            button_color:
            privateLabelPartnerPage.private_label_partner.button_color,
            button_text_color:
            privateLabelPartnerPage.private_label_partner.button_text_color,
            background_color:
            privateLabelPartnerPage.private_label_partner.background_color,
            page_title_text: privateLabelPartnerPage.page_title_text,
            page_title_size: privateLabelPartnerPage.page_title_size,
            page_body_text: privateLabelPartnerPage.page_body_text,
            logo_image_url:
            privateLabelPartnerPage.private_label_partner.logo_url,
        },
        // Next.js will attempt to re-generate the page:
        // - When a request comes in
        // - At most once every 30 seconds
        revalidate: 30,
    }
}

export default function PrivateLabelLandingPage({
                                                    layout,
                                                    background_color,
                                                    button_color,
                                                    button_text_color,
                                                    page_title_text,
                                                    page_title_size,
                                                    page_body_text,
                                                }: PrivateLabelPageProps) {
    const router = useRouter()
    const handleContinueButtonClick = () => {
        router.push('/activate')
    }
    return (
        <ContentWrapper>
            <LeftColumn>
                <ContentHeader size={page_title_size}>
                    {page_title_text}
                </ContentHeader>
                <ContentBody>{page_body_text}</ContentBody>
                <Button
                    label={'Activate your card'}
                    textTransform={'uppercase'}
                    variant={'primary'}
                    width={'standard'}
                    size={'md'}
                    css={{
                        ...{
                            mt: '1rem',
                        },
                        ...customColorButtonProps(
                            button_color,
                            button_text_color,
                        ),
                    }}
                    showArrow={true}
                    onClick={() => handleContinueButtonClick()}
                />
            </LeftColumn>
        </ContentWrapper>
    )
}
